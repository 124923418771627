import gql from 'graphql-tag';

export const getTenantDefaultPickupAddress = gql`
  query getTenantDefaultPickupAddress($tenantId: Int!) {
    getTenantDefaultPickupAddress(tenantId: $tenantId) {
      streetName
      longitude
      latitude
    }
  }
`;

export const getTenantDeliveryOptions = gql`
  query getTenantDeliveryOptions($tenantId: Int!) {
    getTenantDeliveryOptions(tenantId: $tenantId)
  }
`;
